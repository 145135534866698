.__features{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.__features-heading h1{
    font-family: var(--font-family);
    font-size: 34px;
    line-height: 45px;
}

.__features-heading h2{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: -0.04em;
    color: #fff;
}

.__features-heading p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #ff8a71;
    margin-top: 2rem;
}

.__features-container{
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;

    height: 80vh;
 
}

.__apps-container{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem ;


    overflow-y: scroll;
    border-radius: 20px;

}

@media screen and (max-width: 990px) {
    .__features{
        flex-direction: column;
    }

    .__features-heading{
        margin:  0 0 2rem 0;
    }
    
    .__apps-container{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 550px) {

    .__features-heading h1{
        font-size: 28px;
        line-height: 38px;
    }
    .__apps-container{
        grid-template-columns: repeat(1, 1fr);

        margin-left: auto;
        margin-right: auto;
    }


}