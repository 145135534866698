*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    background: var(--color-bg);
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

a {
    color: unset;
    text-decoration: none;
    
}

.gradient__bg {
    background:-moz-radial-gradient(circle at 3% 25%, rgba(50, 76, 101, 1) 0%, rgba(27, 38, 49) 25%);
  
    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(50, 76, 101, 1) 0%, rgba(27, 38, 49) 25%);
  
    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, rgba(50, 76, 101, 1) 0%, rgba(27, 38, 49) 25%);
  
    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, rgba(50, 76, 101, 1) 0%, rgba(27, 38, 49) 25%);
  
    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(50, 76, 101, 1) 0%, rgba(27, 38, 49) 25%);
  }

  .gradient__text{
      background: var(--gradient-text);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 500;

  }

  .gradient__text2{
    background: var(--gradient-text2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


  .section__padding{
      padding: 4rem 6rem;
  }

  .section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

::-webkit-scrollbar{
  width: 5px;
  
}

::-webkit-scrollbar-thumb{
  background-color: var(--color-primary);
  border-radius: 50px;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
    .section__padding {
      padding: 4rem;
    }
  
    .section__margin {
      margin: 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .section__padding {
      padding: 4rem 2rem;
    }
  
    .section__margin {
      margin: 4rem 2rem;
    }  
  }
