
.form-container{
    position: relative;
    min-width: 1100px;
    min-height: 550px;
    display: flex;
    z-index: 10;
}


.form-container .contact-info{
    position: absolute;
    top: 40px;
    width: 350px;
    height: calc(100% - 80px);
    background: var(--color-footer);
    z-index: 1;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;

}

.__svg-container{
    width: 100%;
    height: 100%;
}


.form-container .contact-info ul.info{
    position: relative;
    margin: 20px 0;
}

.form-container .contact-info ul.info li{
    position: relative;
    list-style: none;
    display: flex;
    margin: 20px 0;
    cursor: pointer;
    align-items: flex-start;
}

.form-container .contact-info ul.info li span:nth-child(1) {
    width: 40px;
    min-width: 40px;
}

.form-container .contact-info ul.info li span:nth-child(1) img{
    max-width: 100%;
}

.form-container .contact-info ul.info li span:nth-child(2) {
    color: #1B2631;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 800;
}

.form-container .contact-info .logo{
    position: relative;
    width: 100%;
    text-align: center;
}

.form-container .contact-info .logo img{
    width: 80%;
}





.form-container .contact-form{
    position: absolute;
    padding: 70px 50px;
    padding-left: 250px;
    margin-left: 150px;
    width: calc(100% - 150px);
    height: 100%;
    background: #1B2631;
    

}


.form-container .contact-form h2{
    color: var(--color-primary);
    font-size: 40px;
}

.form-container .contact-form .formBox{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
}

.form-container .contact-form .formBox .inputBox{
    position: relative;
    margin-bottom: 35px;
    color: #c3c3c3;

    padding: 0 20px;

}

.form-container .contact-form .formBox .inputBox.w50{

    width: 50%;
}

.form-container .contact-form .formBox .inputBox.w100{

    width: 100%;
}

.form-container .contact-form .formBox .inputBox input,
.form-container .contact-form .formBox .inputBox textarea 
{
    width: 100%;
    resize: none;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 300;
    border: none;
    outline: none;
    background-color: transparent;
    
    border-bottom: 1px solid whitesmoke;

    color: whitesmoke;

    transition: 0.5s;
}

.form-container .contact-form .formBox .inputBox textarea{
    height: 100px;
}

.form-container .contact-form .formBox .inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 20px;
    pointer-events: none;
    font-size: 18px;
    font-weight: 300;
    transition: 0.3s;
}

.form-container .contact-form .formBox .inputBox input:focus ~ span,
.form-container .contact-form .formBox .inputBox textarea:focus ~ span,  
.form-container .contact-form .formBox .inputBox input:valid ~ span,
.form-container .contact-form .formBox .inputBox textarea:valid ~ span{
    transform: translateY(-25px);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    color: var(--color-primary);
    
}
.form-container .contact-form .formBox .inputBox input:valid,
.form-container .contact-form .formBox .inputBox textarea:valid{
    border-bottom: 1px solid var(--color-primary);
    background-color: var(--color-primary);
    border-radius: 5px;
    color: #1B2631;
}


.sent-content{
    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;

}

.logo-svg{
    width: 100%;
    height: 100%;
}
.logo-svg svg{
    width: 300px;
    height: 300px;
  

}
#LogoSent{
    fill: var(--color-primary);
    stroke: var(--color-primary);
    stroke-width: 0;
    stroke-miterlimit: 10;
    stroke-dasharray: 4650;
    opacity: 10;
    animation: logoo 2s cubic-bezier(0.84,0.05,0.9,0.8);
}

@keyframes logoo {
    0%{
        stroke-width: 2;
        opacity: 0;
        fill: none;
        stroke-dasharray: 4650;
    }
    30%{
        opacity: 10;
        fill: none;
        stroke-dasharray: 4650;
    }

    90%{
        fill: rgba(255,255,255,0);
        stroke-width: 2;
    }

    100%{
        opacity: 10;
        fill: var(--color-primary);
        stroke-dasharray: 0;
        stroke-width: 0;
    }
}

.info2{
    position:absolute;
    margin: 0 auto;
    width: auto;

}

.info2-cont{
    display: flex;
    width: fit-content;
    align-items: center;
    margin: 12px 0;
    
}

.info2 img{
    width: 12%;
    filter: invert(1);
    margin-left: 24px;

}
.info2 p{
    font-size: 20px;
    font-weight: 300;
    margin-left: 10px;
}

.__info2-span{
    background-color: white;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__info2-span svg{
    fill: var(--color-footer);
    width: 75%;
}



.result{
    color: white;
    font-size: 20px;
    font-weight: 400;
}

#Sun{
    animation: sun 4s infinite ease-in-out alternate;
}
#RightHand{
    animation: MoveX 0.5s  infinite ease-in-out alternate;
}

#LeftHand{
    animation: MoveX 0.4s infinite ease-in-out alternate;
}

#Smoke{
    animation: MoveX 2s infinite ease-in-out alternate;
}

#DesktopContent{
    animation: Compress1 3s infinite ease-in-out alternate;

}


#Logo{
    animation: Compress2 3s 0.1s infinite ease-in-out alternate;

}

#Head{
    animation: head 1s infinite ease-in-out alternate;
    
}
#Line1{
    transform: translateY(-5px);
    animation: code 2s infinite alternate;

}

#Line2{
    transform: translateY(5px);
    animation: code 2s infinite alternate;

}

#Line3{
    transform: translateX(-5px);
    animation: code  2s infinite alternate;

}

#Line4{
    transform: translateX(5px);
    animation: code 2s infinite alternate;

}


@keyframes sun {
    0%{
        transform: translateX(0);
    }

    100%{
        transform: translateX(-70px);
    }
    
}

@keyframes MoveX {
    0%{
        transform: translateY(0);
    }

    100%{
        transform: translateY(-4px);
    }
}

@keyframes Compress1 {
    0%{
        transform: translateX(0);
    }

    50%{
        transform: translateX(-50px);
        transform: scale(0.9);
    }

    100%{
        transform: translateX(-50px);
        
    }
}

@keyframes Compress2 {
    0%{
        transform: translateX(0);
    }

    50%{
        transform: translateX(65px);
    }

    100%{
        transform: translateX(60px);
    }
}

@keyframes head {
    0%{
        transform: rotate(1deg);
    }

    50%{
        transform: rotate(2deg);
    }

    100%{
        transform: rotate(2deg);
    }
}


@keyframes code {
    100%{
        transform: unset;
    }
}






@media screen and (max-width: 1220px) {
    .form-container{
        width: 90%;
        min-width: auto;
        margin: 20px;
    }

    .form-container .contact-info{
       top: 0;
       height: 550px;
       position: relative;
    
    }

    .form-container .contact-form{
        position: relative;
        width: calc(100% - 350px);
        padding-left: 0;
        margin-left: 0;
        padding: 40px;
        height: 550px;

     }
    
}

@media screen and (max-width: 990px) {
    section::before{
        display: none;
    }

    .form-container{
        display: flex;
        flex-direction: column-reverse;
    }   

    .form-container .contact-form{
        width: 100%;
        height: auto;
    }

    .form-container .contact-info{
        width: 100%;
        height: auto;
        flex-direction: row;
    }

    .info2{
        position:static;
        display: block;
        background-color: var(--color-footer);
    
    }

    .__svg-container{
        height: auto;
    }


    .info2-cont{
        position:relative;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .info2 img{
        width: 6%;
    
    }


}

@media screen and (max-width: 600px){

    .form_section{
        margin: 0;
        padding: 0;
    }
    
    form-container{
        width: auto;
        padding: 0;
        margin: 0;
    }
    .form-container .contact-form{
        padding: 25px;
    }

    .form-container .contact-info{
        padding: 25px;
    }


    .form-container .contact-form .formBox .inputBox.w50{

        width: 100%;
    }




}