.__d3-container{
    width: 100%;
    height: 100%;
    margin: 0;
    position: fixed;
    background: rgba(27,38,49, 1);
}
canvas { width: 100%; height: 100%; outline: none; }
#container { width: 100%; height: 100%; position: relative; }

.__3d-iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    
}

@media screen and (max-width: 1050px) {
    .__3d-iframe{
        pointer-events: none;
    }
}