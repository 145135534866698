.__blog-container_article{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 20px;
    transition: 0.5s;
    font-family: var(--font-family);
}


.__blog-container_article-circle{
    position: absolute;
    top: 0;
   
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.__blog-container_article-circle::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    clip-path: circle(100px at center);
    transition: 0.3s;
}

.__blog-container_article:hover .__blog-container_article-circle:before{
    
    backdrop-filter: blur(10px) ;
    clip-path: circle(450px at center);
}

.__blog-container_article img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 220px;
    pointer-events: none;
    transition: 0.5s;
}

.__blog-container_article:hover img{
    left: 90%;
    height: 260px;
    z-index: 3;
}

.__blog-container_article-content{
   position: relative; 
   width: 55%;
   left: 20%;
   padding: 20px 20px 20px 40px;
   transition: 0.5s;
   opacity: 0;
   visibility: hidden;
}

.__blog-container_article:hover .__blog-container_article-content{
left: 0;
opacity: 1;
visibility: visible;
}

.__blog-container_article .__blog-container_article-content h2{
    text-transform: uppercase;
    font-size: 2em;
    line-height: 1em;
    margin-bottom: 5px;
    font-weight: 500;
}

.__blog-container_article .__blog-container_article-content h5{
    color: var(--color-primary);
    font-size: 1em;
    line-height: 1em;
    margin-bottom: 10px;
    font-weight: 500;
}

.__blog-container_article .__blog-container_article-content p{
    color: white;
    font-size: 15px;
}

.__blog-container_article .__blog-container_article-content a{
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    color: var(--color-primary);
    padding: 10px 20px;
    border-radius: 5px;
    background-color: transparent;
    margin-top: 10px;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    border: 1px solid var(--color-primary);
    transition: 0.5s;
    
}

.__blog-container_article .__blog-container_article-content a:hover{
    background-color: var(--color-primary);
    color: black;
    letter-spacing: 3px;
}


@media screen and (max-width: 991px) {
    .__blog-container_article{
        background-color: #c3c3c3;
        width: auto;
        max-width: 350px;
        align-items: flex-start;
        margin: 10px;
        transition: 0.06s;
    }

    .__blog-container_article:hover{
        height: 500px;
    }

    .__blog-container_article-content{
        width: 100%;
        left: 0;
        padding: 40px;
     }

     .__blog-container_article:hover img{
        top: 80%;
        left: 50%;
        height: 180px;
    }

}

@media screen and (max-width: 420px) {

    .__blog-container_article-content{
        padding: 20px;
     }
}


/*
.__blog-container_article-image{
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.__blog-container_article-image img{
    width: 100%;
    height: 100%;
}

.__blog-container_article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.__blog-container_article-content p{
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 12px;
    line-height: 35px;
    color: #fff;
}

.__blog-container_article-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.__blog-container_article-content p:last-child{
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .__blog-container_article-content h3{
        font-size: 18px;
        line-height: 25px;
    }
}
*/
