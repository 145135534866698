.__header{
    display: flex;
}

.__header-content{
    flex: 1;
    display: flex;
    justify-content: center; /*Alineación del texto*/
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;

}

.__header-content h1{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    
    
}



  

.__header-content p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    
    color: var(--color-text);
    margin-top: 1.5rem;
}

.__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    position: relative;
 
}


.__header-content__input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;

   
    position: absolute;
    background: rgba(255, 255, 255, 0.05);
    /*
    box-shadow: 0 15px 35px rgba(0,0,0,0.2);
    */
    border: none;
    border-radius: 30px;
    color: white;
    z-index: 1;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
    backdrop-filter: blur(15px);

}

.__header-content__input:hover button{
letter-spacing: 5px;
}

.__header-content__input button::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255,255,255,0.15), transparent);
    transform: skewX(45deg) translateX(0);
    transition: 0.5s;

}

.__header-content__input:hover button::before{
    transform: skewX(45deg) translateX(200%);
}

.__header-content__input::before{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    width: 20%;
    height: 10px;
    background: #676DF4;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0s;
}

.__header-content__input:hover::before{
    bottom: -15px;
    height: 20px;
    width: 80%;
    border-radius: 30px;
    transition-delay: 0s;
    background: #35FFE7;
}

/**/

.__header-3dcontainer{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    position: relative;
}

.__header-image {
    z-index: 5;
    pointer-events: none;
    margin-right: auto;
}

.__header-image img{
    object-fit: cover;
    object-position: 100% 0;
    width:250px;
    height:500px;
}

.__header-fade{
    pointer-events: none;
    background: linear-gradient(0deg, rgba(27,38,49,1) 0%, transparent 25%);
    z-index: 6;
    position: absolute;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1050px) {
    .__header{
        flex-direction: column;
    }

    .__header-content{
     margin: 0 0 3rem;   
    }
}

@media screen and (max-width: 650px) {
    .__header h1{
        font-size: 48px;
        line-height: 60px;
        width: 100%;
        text-align: center;
    }

    .__header p{
        font-size: 16px;
        line-height: 24px;
    }
    .__header-content__people{
        flex-direction: column;
    }
    .__header-content__people p{
        margin: 0;
    }

    

    .__header-content__input input,
    .__header-content__input button {
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .__header h1{
        font-size: 42px;
        line-height: 48px;
    }

    .__header p{
        font-size: 14px;
        line-height: 24px;
    }

    .__header-content__input input,
    .__header-content__input button {
        font-size: 18px;
        line-height: 16px;
    }
}