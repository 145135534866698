

.__App-cad-cont{
    width: 200px;
    height: 200px;
    position: relative;
    transform-style: preserve-3d;
    transition: 0.5s;
    
    font-family: var(--font-family);
}

.card-img{
 position: relative;
 width: 100%;
 height: 100%;
 transform: translateZ(40px);
}

.card-img img{
 width: 100%;
 height: 100%;
 background-size: cover;
}

.card-body{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, -40px) rotateY(180deg);
    width: 85%;
    height: 85%;
    border-radius: 20px;
    overflow: hidden;
    border-radius: 10px;
        background-color: rgba(27, 38, 49, 0.8);
        opacity: 0;
    transition: 0.3s;
}

.__Apps-card:hover .__App-cad-cont{
    transform: rotateY(180deg);
    
}
.__App-cad-cont:hover .card-body{
    opacity: 1;
}

.card-content{
    position: relative;
    opacity: 0;
    transition: 0.5s;
    text-align: center;
    padding: 1rem;

    z-index: 5;
    
}

.card-content h3{
    font-size: 24px;
    font-weight: 500;
    color: var(--color-primary);
    /*text-transform: uppercase;*/
}

.card-content p{
    color: white;
    text-align: justify;
}

.__App-cad-cont:hover .card-content{
    opacity: 1;
    transition: 0.5s;
}
