.modal-container{
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    font-family: var(--font-family);

}

.modal-window{
    top: 120vh;
    position: relative;
    background-color: #1B2631;
    border-radius: 15px;
    padding: 15px;
    z-index: 11;
    min-width: 320px;
}
.modal-close{
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    padding: 10px;
    color: var(--color-primary);
    font-size: 20px;
    font-weight: 700;
    border:none;
    margin-right: 20px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 0px 0px 10px 10px;

}

.modal-bg{
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .modal-window{
        top: 230vh;
    }

    .modal-window{
        width: 75%;
    }
}

@media screen and (max-width: 550px) {
    .modal-window{
        top: 280vh;
    }

    .modal-window{
       height: 70vh;
    }
}