.__what {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);
    position: relative;

    
}

.emoji{
    
    background-clip: text;
    -webkit-background-clip: text;
}
.Insta-container{
    padding: 5px;
}

.Insta-container h1{
    text-align: left;
    font-size: 3rem;
    font-weight: 400;
    transition: 0.5s;
}

.Insta-container h1:hover{
    letter-spacing: 5px;
}

.Insta-container hr{
    background-color: var(--color-primary);;
    height: 1px;
    border: none;
    border-radius: 5px;
    
}

.Insta-content{
    width: 1000px;
    height: 500px;
}

.Insta-content iframe{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border:none;
    overflow:hidden;
}


.wave{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-size: 1280px 100px;
    pointer-events: none;
    transition: 0.3s;
    
}

.__what:hover .wave{
    background: url(../../assets/Wave3.png);
}

.__what:hover .wave.wave1{
    height: 120px;
}
.__what:hover .wave.wave3{
    height: 80px;
}


.wave.wave1{
    animation: animate 30s linear infinite; 
    z-index: 3;
    opacity: 0.5;
    animation-delay: 0s;
    bottom: 0;
    background: url(../../assets/Wave3.png);

}

.wave.wave2{
    animation: animate2 15s linear infinite;
    z-index: 2; 
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 0px;
    background: url(../../assets/Wave2.png);

}

.wave.wave3{
    animation: animate2 30s linear infinite;
    z-index: 1; 
    opacity: 0.5;
    animation-delay: -2s;
    bottom: 0;
    background: url(../../assets/Wave1.png);
}

@keyframes animate {
    0%{
        background-position-x: 0;
    }

    100%{
        background-position-x: 1280px;
    }
}

@keyframes animate2 {
    0%{
        background-position-x: 0;
    }

    100%{
        background-position-x: -1280px;
    }
}


@keyframes animate3 {
    0%{
        background-position-x: 0;
    }

    100%{
        background-position-x: -1280px;
    }
}


.__what-feature{
    display: flex;
}

.__what-feature .__features-container__feature{
    margin: 0;

}

.__what-feature .__features-container__feature-text{
    max-width: 700px;
    
}

.__what-heading:first-child h1{
    font-family: var(--font-family);
    font-size: 50px;
    line-height: 45px;
    max-width: 510px;
}
.__what-heading{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 8px;

    margin: 4rem 0 2rem;
}
.__what-heading h1{
    font-family: var(--font-family);
    font-size: 34px;
    line-height: 45px;
    max-width: 510px;
}

.__what-heading p{
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    max-width: 510px;
    
    color: var(--color-subtext);
    cursor: pointer;
}

.__what-span{
    font-size: 2rem;
    font-weight: 500;
  }

.__what-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    
    z-index: 5;
    margin: 2rem;
}

/* Customizing feature component as per needs */
.__what-container .__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.__what-container .__features-container_feature-text {
    margin-top: 0.5rem;
}

.__what-list{
    background-color: mintcream;
}

@media screen and (max-width: 990px) {

    
    .Insta-container h1{
        font-size: 2rem;
    }
    
    .Insta-content{
        width: 100%;
    }
    

}


@media screen and (max-width: 850px) {
    .__what-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .__what-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .__what-feature .__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .__what-feature .__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .__what-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .__what-container .__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}