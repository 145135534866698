.__cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}

.__cta-content{
    width: 25%;
}


.__cta-content h3{
    font-family: var(--font-family);
    font-weight:900;
    font-size: 32px;
    line-height: 45px;
    color: #111;  

}

.__cta-apps{
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5rem ;


    overflow-y: scroll;
    border-radius: 20px;

}

@media screen and (max-width: 990px) {

    .__cta-apps{
        grid-template-columns: repeat(1, 1fr);
    }

    .__cta-content{
        width: 100%;
    }

    .__cta-content h3{
        text-align: center;
    }




}

@media screen and (max-width: 650px) {
    .__cta{
        flex-direction: column;
    }

    .__cta-apps{
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .__cta{
        margin: 4rem 2rem;
    }

    .__cta-content h3{
        font-size: 24px;
        line-height: 32px;
    }


}