.__features-container__feature{
    width: 100%;
    /*display: flex;*/
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.__features-container__feature-title{
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}

.__features-container__feature-title h2{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
    letter-spacing: -0.04em;
    color: #fff;
}

.__features-container__feature-title div{
    width: 50px;
    height: 3px;
    background: var(--gradient-bar);
    margin-bottom: 0.25rem;
}

.__features-container_feature-text {
    flex: 2;
   /* max-width: 100rem;*/
    display: flex;
}

.__features-container_feature-text p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
}

.__features-container_feature-list {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
}
.__features-container_feature-list a {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    transition: 0.5s;
}

.__features-container_feature-list a:hover {
    letter-spacing: 2px;
    font-size: 20px;
    color: var(--color-primary);
}

.Modal-btn{
    padding: 5px;
    background-color: mediumseagreen;
    color: #111;
}

@media screen and (max-width: 550px) {
    .__features-container__feature-title h1{
        font-size: 14px;
        line-height: 22px;
    }

    .__features-container__feature-title p{
        font-size: 12px;
        line-height: 20px;
    }

    .__features-container__feature{
        margin: 1rem 0;
    }
}

