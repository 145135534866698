
.bg_layer{
    background: linear-gradient(149.96deg, #37F9E8 1.52%, #676DF4 100%);
}

.__footer{

    color: white;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: rgba(0, 0, 0, 0);
    font-family: var(--font-family);

    transition: 0.5s;
    

}

.__footer:hover{
    background-color: rgba(0, 0, 0, 0.25);
}


.__footer-heading{
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.__footer-heading h1{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 62px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}


.__form-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
    justify-content: center;
    

}

.__footer-copyright{
    text-align: center;
}

.__footer-copyright p{
    margin-top: 10px;
}



@media screen and (max-width: 990px ) {
    .__footer-copyright{
        margin-top: 30px;
    }
}

@media screen and (max-width: 850px ) {
    .__footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }

}


@media screen and (max-width: 550px ) {
    .__footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }

}

@media screen and (max-width: 550px ) {
    .__footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}