.__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.__navbar-links_logo{
    margin-right: 2rem;
}



.__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.__navbar-links_sing{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.__navbar-links_container p,
.__navbar-links_sing p,
.__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
}

.__navbar-links_container p:after,
.__navbar-links_sing p:after,
.__navbar-menu_container p:after{
content: "";
position: absolute;
background-color: var(--color-primary);
height: 1px;
width: 0;
left: 0;
bottom: -3px;
transition: 0.3s;
}
.__navbar-links_container p:hover, 
.__navbar-links_sing p:hover,
.__navbar-menu_container p:hover {
    color: var(--color-primary);
}

.__navbar-links_container p:hover:after,
.__navbar-links_sing p:hover:after,
.__navbar-menu_container p:hover:after {
    width: 100%;
}

.__navbar-sing button,
.__navbar-menu_container button {   
    padding: 0.5rem 1rem;
    color: #fff;
    background: red;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.__navbar-menu{
    margin-left: 1rem;

    display: none;
    position: relative;
}

.__navbar-menu svg{
    cursor: pointer;
}

.__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(15px);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
}


.__navbar-menu_container p{
    margin: 1rem 0;
}

.__navbar-menu_container-links-sing{
    display: none;
}

@media screen and (max-width: 1050px) {

    .__navbar-links_container{
        display: none;
    }

    .__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width: 700px) {

    .__navbar{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {

    .__navbar{
        padding: 2rem;
    }
    .__navbar-sing{
        display: none;
    }

    .__navbar-menu_container{
        top: 20px;
    }

    .__navbar-menu_container-links-sing{
        display: block;
    }
}